import { useState } from "react";
import { Button } from "react-bootstrap";
import styles from "./Collapsible.module.scss";

export type CollapsibleProps = React.PropsWithChildren<{
	className?: string;
}>;

const Collapsible = ({ className, children }: CollapsibleProps): JSX.Element => {
	const [isCollapsed, setIsCollapsed] = useState(true);

	return (
		<div className={`mb-4 ${styles["collapsible"]} ${className ?? ""}`}>
			<div className={isCollapsed ? styles["collapsed"] : ""}>{children}</div>
			<Button variant="link" className="p-0" onClick={() => setIsCollapsed(c => !c)}>
				{isCollapsed ? "Vis mer" : "Vis mindre"}
			</Button>
		</div>
	);
};

export default Collapsible;
export { Collapsible };
