import { useState, useEffect } from "react";
import { Instrument } from "../models/Instrument";
import * as Backend from "../models/Backend";

// Cache of the existing instruments
let instrumentCache: Instrument[] | null = null;

/** Loads instruments from backend, caching them for reuse. Returns null until they are fetched */
const useInstruments = (): Instrument[] | null => {
	const [instruments, setInstruments] = useState(instrumentCache);
	useEffect(() => {
		void (async () => {
			const instruments = await Backend.instruments();
			instrumentCache = instruments;
			setInstruments(instruments);
		})();
	}, []);

	return instruments;
};

const useInstrument = (instrumentId: Instrument["id"] | null): Instrument | null => {
	const instruments = useInstruments();

	return instruments?.find(i => i.id === instrumentId) ?? null;
};

export default useInstruments;
export { useInstruments, useInstrument };
