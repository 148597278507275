import * as yup from "yup";

export interface BaseType {
	id: string;
	createdAt: Date;
	updatedAt: Date;
}

export const baseTypeSchema = yup.object({
	id: yup.string().required(),
	createdAt: yup.date().required(),
	updatedAt: yup.date().required()
});

baseTypeSchema as yup.SchemaOf<BaseType>;
