import { Image as ImageType } from "../../models/Image";
import { Carousel as BSCarousel, CarouselItem } from "react-bootstrap";
import Image from "./Image";

type CarouselProps = {
	images: ImageType[];
	size: "orig" | "thumb";
};

const Carousel = ({ images, size }: CarouselProps): JSX.Element => (
	<BSCarousel>
		{images.map(image => (
			<CarouselItem key={image.id}>
				<Image image={image} alt="" size={size} />
			</CarouselItem>
		))}
	</BSCarousel>
);

export default Carousel;
export { Carousel };
