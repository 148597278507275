import * as yup from "yup";
import { BaseType, baseTypeSchema } from "./BaseType";
import { BottomType } from "./BottomType";
import { LifeType } from "./LifeType";
import { LitterType } from "./LitterType";

export interface Image extends BaseType {
	lifetypeId: LifeType["id"] | null;
	littertypeId: LitterType["id"] | null;
	bottomtypeId: BottomType["id"] | null;
	imagePath: string;
	citation: string | null;
}

export const imageSchema = baseTypeSchema.shape({
	lifetypeId: yup.string().nullable(),
	littertypeId: yup.string().nullable(),
	bottomtypeId: yup.string().nullable(),
	imagePath: yup.string().required(),
	citation: yup.string().nullable()
});

imageSchema as yup.SchemaOf<Image>;
