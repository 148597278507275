import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./StandardLayout.module.scss";

export type StandardLayoutProps = React.PropsWithChildren<{ header: string }>;

const StandardLayout = ({ header, children }: StandardLayoutProps): JSX.Element => (
	<React.Fragment>
		<Container fluid className={`${styles["standard-layout"]} px-0`}>
			<Row className="gx-0 mx-0 p-5">
				<Col as="header">
					<h1 className="fw-bold text-uppercase">{header}</h1>
				</Col>
			</Row>
		</Container>
		<Container>
			<Row>
				<Col as="main" className="mt-4">
					{children}
				</Col>
			</Row>
		</Container>
	</React.Fragment>
);

export default StandardLayout;
export { StandardLayout };
