import { StrictMode } from "react";
import Router from "./components/application/Router";
import { I18nextProvider } from "react-i18next";
import { i18next } from "./i18n";
import { ToastContainer } from "react-toastify";

const App = (): JSX.Element => (
	<StrictMode>
		<I18nextProvider i18n={i18next}>
			<Router />
			<ToastContainer />
		</I18nextProvider>
	</StrictMode>
);

export default App;
