import Loading from "../application/Loading";
import { StandardLayout } from "../layouts/StandardLayout";
import { Col, FormControl, Row } from "react-bootstrap";
import { useState } from "react";
import { ExcursionList } from "../excursion/ExcursionList";
import * as Backend from "../../models/Backend";
import useSWR from "swr";
import { Link } from "react-router-dom";
import styles from "./Excursions.module.scss";
import * as dateFns from "date-fns";
import { formatToUTC } from "../../utils/date";
import qs from "query-string";
import env from "../../env";
import Collapsible from "../collapsible/Collapsible";

const Excursions = (): JSX.Element => {
	const [activeTab, setActiveTab] = useState<"mine" | "all">("mine");
	const [before, setBefore] = useState(new Date());
	const [after, setAfter] = useState(dateFns.subMonths(before, 1));

	// Set the date range to be at the start/end of the days in the range
	const processedDateRange = {
		before: dateFns.endOfDay(before),
		after: dateFns.startOfDay(after)
	};
	const mine = activeTab === "mine";

	// Ask for the excursions within that range
	const { data: excursions, mutate } = useSWR({ mine, ...processedDateRange }, Backend.getExcursions);

	return (
		<StandardLayout header="Ekskursjoner">
			<Row>
				<Col>
					<Collapsible>
						<p>
							Velkommen til ekskursjonsmodulen. Denne har 3 nivåer: Ekskursjon – Stasjon – Observasjon.
							Selv om du kanskje er ivrig etter å komme i gang med å registrere det du observerer, er det
							viktig at både informasjon om selve ekskursjonen og de stasjoner dere oppretter blir
							dokumentert. En ekskursjon kan ha mange stasjoner, og vi må vite hvor og når stasjonen blir
							opprettet, og evt. hvilket redskap eller instrument som er benyttet. En stasjon kan være
							rent instrumentelt (temperatur, dybde, sikt, saltholdighet) eller den kan være
							utgangspunktet for observasjoner av fauna og avfall. I dette skjermbildet kan du også hente
							ned datafiler i det tidsrom du selv ønsker.
						</p>
					</Collapsible>
					<Link className={`${styles["new-excursion-button"]} w-100 py-3 mb-4`} to="/createExcursion">
						Ny ekskursjon
					</Link>
				</Col>
			</Row>
			<Row>
				<Col onClick={() => setActiveTab("mine")}>
					<div className={`${styles["tab"]} ${activeTab === "mine" ? styles["active"] : ""}`}>
						Mine ekskursjoner
					</div>
				</Col>
				<Col onClick={() => setActiveTab("all")}>
					<div className={`${styles["tab"]} ${activeTab === "all" ? styles["active"] : ""}`}>
						Alle ekskursjoner
					</div>
				</Col>
			</Row>
			<Row className="mt-3">
				<Col xs={12} className="text-center">
					Vis ekskursjoner mellom
				</Col>
				<Col>
					<FormControl
						type="date"
						value={formatToUTC(after)}
						onChange={e => setAfter(new Date(e.target.value))}
					/>
				</Col>
				<Col>
					<FormControl
						type="date"
						value={formatToUTC(before)}
						onChange={e => setBefore(new Date(e.target.value))}
					/>
				</Col>
				<Col xs={12} className="text-center">
					<a
						href={`${env.BACKEND_BASE_PATH}/csv/excursions?${qs.stringify({
							mine,
							after: processedDateRange.after.toISOString(),
							before: processedDateRange.before.toISOString()
						})}`}
						target="_blank"
						rel="noopener noreferer"
					>
						Last ned alle som CSV
					</a>
				</Col>
			</Row>
			<Row>
				<Col>
					{excursions === undefined ? (
						<Loading />
					) : (
						<ExcursionList excursions={excursions} onDeleted={() => void mutate()} />
					)}
				</Col>
			</Row>
		</StandardLayout>
	);
};

export default Excursions;
export { Excursions };
