import { LifeType } from "../models/LifeType";
import * as Backend from "../models/Backend";
import useSWR from "swr";

/** Loads lifeTypes from backend, caching them for reuse. Returns null until they are fetched */
const useLifeTypes = (): LifeType[] | null => {
	const { data: lifeTypes } = useSWR("lifeTypes", Backend.lifeTypes);

	return lifeTypes ?? null;
};

export default useLifeTypes;
export { useLifeTypes };
