import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NotFound = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Container>
			<Row>
				<Col>
					<p>{t("application:pageNotFound")}</p>
					<Link to="/">{t("application:toFrontpage")}</Link>
				</Col>
			</Row>
		</Container>
	);
};

export default NotFound;
export { NotFound };
