import React, { useState, useEffect, useRef } from "react";
import { Navbar as BSNavbar, NavbarBrand, Container, Nav, Button } from "react-bootstrap";
import env from "../../env";
import styles from "./Navbar.module.scss";
import logo from "../../assets/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import * as Backend from "../../models/Backend";
import { toast } from "react-toastify";

const Navbar = (): JSX.Element => {
	// Handle dropdown state
	const [showDropdown, setShowDropdown] = useState<boolean>(false);
	const [dropdownTopOffset, setDropdownTopOffset] = useState<string>("0");
	const navbar = useRef<HTMLElement>(null);
	const dropdown = useRef<HTMLDivElement>(null);
	useEffect(() => {
		setDropdownTopOffset(String(navbar.current?.clientHeight ?? 0) + "px");
	}, [navbar]);

	// Close the dropdown if a click is registered outside it
	useEffect(() => {
		// Don't do anything if the dropdown is not open
		if (!showDropdown) {
			return;
		}

		const handler = (e: MouseEvent) => {
			// Ignore clicks in the dropdown itself
			if (e.target !== dropdown.current) {
				setShowDropdown(false);
			}
		};

		document.addEventListener("click", handler);

		return () => {
			document.removeEventListener("click", handler);
		};
	}, [showDropdown]);

	return (
		<React.Fragment>
			<BSNavbar ref={navbar} className={`${styles["navbar"]} text-uppercase pt-0`} expand="lg">
				<Container fluid className="px-0">
					<NavbarBrand as="a" href={env.FRONTPAGE_BASE_URL}>
						<img src={logo} alt="logo" className="ms-1 me-4" />
						Dugnad for Havet
					</NavbarBrand>
					<BSNavbar.Collapse>
						<Nav className="ms-auto">
							<Nav.Link href={`${env.FRONTPAGE_BASE_URL}/registrerFunn`}>Registrer funn</Nav.Link>
							<Nav.Link href={`${env.FRONTPAGE_BASE_URL}/dataportal`}>Se funn</Nav.Link>
						</Nav>
					</BSNavbar.Collapse>
					<Button variant="link" onClick={() => setShowDropdown(s => !s)} data-testid="hamburger">
						<FontAwesomeIcon icon={faBars} />
					</Button>
				</Container>
			</BSNavbar>
			{showDropdown ? (
				<div
					ref={dropdown}
					className={styles["dropdown-hack"]}
					style={{ top: dropdownTopOffset }}
					data-testid="dropdown"
				>
					<ul className="list-unstyled">
						<li>
							<a href={env.FRONTPAGE_BASE_URL}>Forside</a>
						</li>
						<li>
							<a href={`${env.FRONTPAGE_BASE_URL}/registrerFunn`}>Registrer funn</a>
						</li>
						<li>
							<a href={`${env.FRONTPAGE_BASE_URL}/dataportal`}>Se funn</a>
						</li>
						<li>
							<a href={`${env.FRONTPAGE_BASE_URL}/livetIHavet`}>Våre utvalgte arter</a>
						</li>
					</ul>
					<ul className="list-unstyled">
						<li>
							<a href={`${env.FRONTPAGE_BASE_URL}/om`}>Om Dugnad for Havet</a>
						</li>
					</ul>
					<ul className="list-unstyled">
						<li>
							<a
								onClick={e => {
									e.preventDefault();

									void (async () => {
										try {
											await Backend.logout();
											window.location.href = env.FRONTPAGE_BASE_URL;
										} catch (err) {
											toast("Kunne ikke logge ut");
										}
									})();
								}}
								href={env.FRONTPAGE_BASE_URL}
							>
								Logg ut
							</a>
						</li>
					</ul>
				</div>
			) : null}
		</React.Fragment>
	);
};

export default Navbar;
export { Navbar };
