import { User } from "../models/User";
import useSWR from "swr";
import * as Backend from "../models/Backend";

/** Extracts the logged in user from the user context */
const useUser = (): { loaded: boolean; user: User | null; refresh: () => void } => {
	// Get the user object and refresh it every once in a while
	const { data: user, mutate } = useSWR("user data", Backend.userId, { refreshInterval: 60 * 1000 });

	return { loaded: user !== undefined, user: user ?? null, refresh: () => void mutate() };
};

export { useUser };
