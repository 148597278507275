import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button, FormControl, FormGroup } from "react-bootstrap";
import { LoginCredentials, loginCredentialsSchema } from "../../models/LoginCredentials";

export type LoginFormProps = {
	onSubmit: (credentials: LoginCredentials) => void | Promise<void>;
};

const LoginForm = ({ onSubmit }: LoginFormProps) => {
	return (
		<Formik
			initialValues={{ email: "", password: "" }}
			validationSchema={loginCredentialsSchema}
			onSubmit={async (values: LoginCredentials, { setSubmitting }) => {
				// Set submit flag so the submit button can be disabled
				setSubmitting(true);

				try {
					await onSubmit(values);
				} catch (err) {
					console.error(err);
				} finally {
					setSubmitting(false);
				}
			}}
		>
			{({ isSubmitting }) => (
				<Form>
					{/* Email */}
					<FormGroup>
						<Field as={FormControl} name="email" type="email" placeholder="E-postadresse" />
						<ErrorMessage component={"p"} className="text-danger" name="email" />
					</FormGroup>

					{/* Password */}
					<FormGroup className="mt-3">
						<Field as={FormControl} name="password" type="password" placeholder="Passord" />
						<ErrorMessage component={"p"} className="text-danger" name="password" />
					</FormGroup>

					{/* Submit */}
					<FormGroup className="mt-3">
						<Button
							variant="success"
							type="submit"
							className="w-100 text-uppercase"
							disabled={isSubmitting}
						>
							Logg inn
						</Button>
					</FormGroup>
				</Form>
			)}
		</Formik>
	);
};

export default LoginForm;
export { LoginForm };
