import Loading from "../application/Loading";
import { Row, Col } from "react-bootstrap";
import { Excursion as ExcursionType } from "../../models/Excursion";
import { Station, Station as StationType } from "../../models/Station";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useExcursion } from "../../hooks/useExcursion";
import { Link, generatePath } from "react-router-dom";
import { StationList } from "./StationList";
import { formatToUTC } from "../../utils/date";
import useSWR from "swr";
import * as Backend from "../../models/Backend";
import { StandardLayout } from "../layouts/StandardLayout";
import { Area } from "../../models/Area";
import { useUser } from "../../hooks/useUser";
import env from "../../env";
interface RawExcursionProps {
	excursion: ExcursionType;
	stations: StationType[] | null;
	onStationDeleted: (station: Station) => void | Promise<void>;
}

const RawExcursion = ({ excursion, stations, onStationDeleted }: RawExcursionProps): JSX.Element => {
	const [area, setArea] = useState<Area | null>(null);
	const { user } = useUser();

	useEffect(() => {
		void (async () => {
			// XXX Hack to make the tests not fail due to unexpected state update. This particular
			// functionality is not being tested
			if (env.NODE_ENV === "test") {
				return;
			}

			const area = await Backend.getArea(excursion.areaId);
			setArea(area);
		})();
	}, [excursion.areaId]);

	let canCreateStation = false;
	if (user?.type === "dfh-user" && user.userId === excursion.owner) {
		canCreateStation = true;
	} else if (user?.type === "participant" && user.excursionId === excursion.id) {
		canCreateStation = true;
	}

	return (
		<StandardLayout header={`Ekskursjon - ${excursion.name}`}>
			<Link to={"/excursions/"} className="btn btn-primary">
				Tilbake til Alle Ekskursjoner
			</Link>
			<Row className="justify-content-center">
				<Col xs="auto" className="fs-2 text-center">
					<dt>PIN</dt>
					<dd>{excursion.pin ?? "Ikke tilgjengelig"}</dd>
				</Col>
			</Row>
			<Row as="dl">
				<Col xs={4} sm={3} md={2}>
					<dt>Eier</dt>
					<dd>{excursion.ownername ?? "Anonym"}</dd>
				</Col>
				<Col xs={4} sm={3} md={2}>
					<dt>Navn</dt>
					<dd>{excursion.name}</dd>
				</Col>
				<Col xs={4} sm={3} md={2}>
					<dt>Skole</dt>
					<dd>{excursion.school}</dd>
				</Col>
				<Col xs={4} sm={3} md={2}>
					<dt>Nummer</dt>
					<dd>{excursion.number}</dd>
				</Col>
				<Col xs={4} sm={3} md={2}>
					<dt>Område</dt>
					<dd>{area?.name ?? ""}</dd>
				</Col>
				<Col xs={4} sm={3} md={2}>
					<dt>Dato</dt>
					<dd>{excursion.dateTime === null ? null : formatToUTC(excursion.dateTime)}</dd>
				</Col>
			</Row>
			{canCreateStation ? (
				<Row>
					<Col>
						<Link
							to={generatePath("/createStation/:excursionId", {
								excursionId: excursion.id
							})}
							className="btn btn-primary"
						>
							Ny stasjon
						</Link>
					</Col>
				</Row>
			) : null}
			<Row>
				<Col>
					{stations === null || stations.length === 0 ? (
						"Ingen opprettede stasjoner"
					) : (
						<StationList stations={stations} onDeleted={onStationDeleted} excursion={excursion} />
					)}
				</Col>
			</Row>
		</StandardLayout>
	);
};

const Excursion = (): JSX.Element => {
	const { id } = useParams<"id">();
	const { excursion } = useExcursion(id as ExcursionType["id"]);

	const { data: stations, mutate } = useSWR(id, Backend.getStations, {
		refreshInterval: 10 * 1000
	});

	return excursion === null ? (
		<Loading />
	) : (
		<RawExcursion
			excursion={excursion}
			stations={stations ?? null}
			onStationDeleted={station => {
				if (stations === undefined) {
					return;
				}

				const newStationsArray = stations.filter(({ id }) => id !== station.id);
				void mutate(newStationsArray);
			}}
		/>
	);
};

export default Excursion;
export { Excursion, RawExcursion };
