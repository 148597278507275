import { LifeType } from "../../models/LifeType";
import { Row, Col } from "react-bootstrap";
import { Carousel } from "../images/Carousel";

interface ViewLifeTypeProps {
	lifeType: LifeType;
}

const ViewLifeType = ({ lifeType }: ViewLifeTypeProps): JSX.Element => (
	<>
		{lifeType.imageTypeList.length > 0 ? (
			<Row>
				<Col>
					<Carousel images={lifeType.imageTypeList} size="thumb" />
				</Col>
			</Row>
		) : null}
		<Row as="dl">
			<Col xs={4} sm={3} md={2}>
				<dt>Art</dt>
				<dd>{lifeType.species ?? null}</dd>
			</Col>
			<Col xs={4} sm={3} md={2}>
				<dt>Rike</dt>
				<dd>{lifeType.isAnimal ? "Dyrerike" : "Planterike"}</dd>
			</Col>
			<Col xs={4} sm={3} md={2}>
				<dt>Slekt</dt>
				<dd>{lifeType.genus ?? null}</dd>
			</Col>
			<Col xs={4} sm={3} md={2}>
				<dt>Familie</dt>
				<dd>{lifeType.family ?? null}</dd>
			</Col>
			<Col xs={4} sm={3} md={2}>
				<dt>Orden</dt>
				<dd>{lifeType.bioOrder ?? null}</dd>
			</Col>
			<Col xs={4} sm={3} md={2}>
				<dt>Klasse</dt>
				<dd>{lifeType.bioClass ?? null}</dd>
			</Col>
			<Col xs={4} sm={3} md={2}>
				<dt>Rekke</dt>
				<dd>{lifeType.phylum ?? null}</dd>
			</Col>
		</Row>
	</>
);

export default ViewLifeType;
export { ViewLifeType };
