import Loading from "../application/Loading";
import { BottomType } from "../../models/BottomType";
import { Instrument } from "../../models/Instrument";
import * as Backend from "../../models/Backend";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useBottomTypes } from "../../hooks/useBottomTypes";
import { useInstruments } from "../../hooks/useInstruments";
import StationForm from "../station/StationForm";
import { toast } from "react-toastify";
import { Station } from "../../models/Station";
import useStation from "../../hooks/useStation";
import StandardLayout from "../layouts/StandardLayout";

interface RawEditStationProps {
	station: Station;
	bottomTypes: BottomType[];
	instruments: Instrument[];
}

const RawEditStation = ({ station, bottomTypes, instruments }: RawEditStationProps): JSX.Element | null => {
	const navigate = useNavigate();

	return (
		<StandardLayout header="Rediger stasjon">
			<StationForm
				bottomTypes={bottomTypes}
				instruments={instruments}
				station={station}
				onSubmit={async data => {
					const modifiedStation = {
						...data,
						id: station.id,
						excursionId: station.excursionId
					};

					// Send it to backend
					try {
						const { id } = await Backend.editStation(modifiedStation);

						// Navigate to the excursion
						navigate(generatePath("/viewStation/:id", { id }));
					} catch (err) {
						console.error(err);

						toast("Noe gikk galt med redigering av stasjonen. Prøv igjen.");
					}
				}}
			/>
		</StandardLayout>
	);
};

const EditStation = (): JSX.Element | null => {
	const bottomTypes = useBottomTypes();
	const instruments = useInstruments();
	const { stationId } = useParams<"stationId">();
	const { station } = useStation(stationId as string);

	return bottomTypes === null || instruments === null || station === null ? (
		<Loading />
	) : (
		<RawEditStation station={station} bottomTypes={bottomTypes} instruments={instruments} />
	);
};

export default EditStation;
export { EditStation, RawEditStation };
