import Loading from "../application/Loading";
import * as Backend from "../../models/Backend";
import { ManualObservation } from "../../models/ManualObservation";
import { ManualObservationForm } from "../manualObservation/ManualObservationForm";
import { toast } from "react-toastify";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import useManualObservation from "../../hooks/useManualObservation";
import useLifeType from "../../hooks/useLifeType";
import useLitterType from "../../hooks/useLitterType";
import { LitterType } from "../../models/LitterType";
import { LifeType } from "../../models/LifeType";
import ViewLitterType from "../litterType/ViewLitterType";
import ViewLifeType from "../lifeType/ViewLifeType";
import StandardLayout from "../layouts/StandardLayout";

interface RawEditManualObservationProps {
	manualObservation: ManualObservation;
	observationType: LitterType | LifeType;
}

const RawEditManualObservation = ({
	manualObservation,
	observationType
}: RawEditManualObservationProps): JSX.Element | null => {
	const navigate = useNavigate();

	const fields = {
		registerSeen: observationType.registerSeen ?? false,
		registerDensityAmount: observationType.registerDensityAmount ?? false,
		registerDensityPercent: observationType.registerDensityPercent ?? false,
		registerLength: observationType.registerLength ?? false,
		registerSex: (observationType as { registerSex?: boolean }).registerSex ?? false
	};

	return (
		<StandardLayout header="Rediger observasjon">
			{manualObservation.type === "litter" ? (
				<ViewLitterType litterType={observationType as LitterType} />
			) : (
				<ViewLifeType lifeType={observationType as LifeType} />
			)}
			<ManualObservationForm
				manualObservation={manualObservation}
				fields={fields}
				onSubmit={async values => {
					const modifiedManualObservation = {
						...values,
						type: manualObservation.type,
						typeId: manualObservation.typeId,
						participantId: manualObservation.participantId,
						stationId: manualObservation.stationId,
						id: manualObservation.id
					};

					try {
						await Backend.editManualObservation(modifiedManualObservation);

						navigate(generatePath("/viewStation/:stationId", { stationId: manualObservation.stationId }));
					} catch (err) {
						console.error(err);

						toast("noe gikk galt med redigeringen av den manuelle observasjonen. Prøv igjen.");
					}
				}}
			/>
		</StandardLayout>
	);
};

interface IntermediateProps {
	manualObservation: ManualObservation;
	useObservationType: typeof useLifeType | typeof useLitterType;
}

const Intermediate = ({ manualObservation, useObservationType }: IntermediateProps): JSX.Element => {
	const observationType = useObservationType(manualObservation.typeId);
	return observationType === null ? (
		<Loading />
	) : (
		<RawEditManualObservation observationType={observationType} manualObservation={manualObservation} />
	);
};

const EditManualObservation = (): JSX.Element | null => {
	const { id } = useParams<"id">();

	if (id === undefined) {
		throw new Error("Need an observation type ID param from router");
	}

	const manualObservation = useManualObservation(id);
	if (manualObservation === null) {
		return <p>Observasjon ikke funnet</p>;
	}

	const useObservationType = manualObservation.type === "life" ? useLifeType : useLitterType;
	return <Intermediate useObservationType={useObservationType} manualObservation={manualObservation} />;
};

export default EditManualObservation;
export { EditManualObservation, RawEditManualObservation };
