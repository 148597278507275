import { useState, useEffect } from "react";
import { BottomType } from "../models/BottomType";
import * as Backend from "../models/Backend";

// Cache of the existing bottomTypes
let bottomTypeCache: BottomType[] | null = null;

/** Loads bottomTypes from backend, caching them for reuse. Returns null until they are fetched */
const useBottomTypes = (): BottomType[] | null => {
	const [bottomTypes, setBottomTypes] = useState(bottomTypeCache);
	useEffect(() => {
		void (async () => {
			const bottomTypes = await Backend.bottomTypes();
			bottomTypeCache = bottomTypes;
			setBottomTypes(bottomTypes);
		})();
	}, []);

	return bottomTypes;
};

const useBottomType = (bottomTypeId: BottomType["id"] | null): BottomType | null => {
	const bottomTypes = useBottomTypes();

	return bottomTypes?.find(bt => bt.id === bottomTypeId) ?? null;
};

export default useBottomTypes;
export { useBottomTypes, useBottomType };
