import * as yup from "yup";
import { BaseType, baseTypeSchema } from "./BaseType";
import { Image, imageSchema } from "./Image";

export interface LitterType extends BaseType {
	name: string | null;
	code: string;
	groupCode: string | null;
	materialGroup: string | null;
	description: string | null;
	registerSeen: boolean | null;
	registerDensityAmount: boolean | null;
	registerDensityPercent: boolean | null;
	registerLength: boolean | null;
	comment: string | null;
	imageTypeList: Image[];
}

export const litterTypeSchema = baseTypeSchema.shape({
	name: yup.string().nullable(),
	code: yup.string().required(),
	groupCode: yup.string().nullable(),
	materialGroup: yup.string().nullable(),
	description: yup.string().nullable(),
	registerSeen: yup.boolean().nullable(),
	registerDensityAmount: yup.boolean().nullable(),
	registerDensityPercent: yup.boolean().nullable(),
	registerLength: yup.boolean().nullable(),
	comment: yup.string().nullable(),
	imageTypeList: yup.array(imageSchema.required()).required()
});

litterTypeSchema as yup.SchemaOf<LitterType>;
