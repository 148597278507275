import { Station } from "../models/Station";
import { useState, useEffect } from "react";
import * as Backend from "../models/Backend";

const useStation = (stationId: Station["id"]): { loading: boolean; station: Station | null } => {
	const [loading, setLoading] = useState(true);
	const [station, setStation] = useState<Station | null>(null);

	useEffect(() => {
		void (async () => {
			setLoading(true);

			const station = await Backend.getStation(stationId);

			setStation(station);
			setLoading(false);
		})();
	}, [stationId]);

	return { loading, station };
};

export default useStation;
export { useStation };
