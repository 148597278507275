import Loading from "../application/Loading";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Backend from "../../models/Backend";
import { ManualObservation } from "../../models/ManualObservation";
import { Link, generatePath } from "react-router-dom";
import useLifeType from "../../hooks/useLifeType";
import useLitterType from "../../hooks/useLitterType";
import { Excursion } from "../../models/Excursion";
import { useUser } from "../../hooks/useUser";
import { LifeType } from "../../models/LifeType";

interface ManualObservationListProps {
	manualObservations: ManualObservation[] | null;
	excursion: Excursion | null;
	onDeleted: (manualObservation: ManualObservation) => void | Promise<void>;
}

const ObservationList = ({ excursion, manualObservations, onDeleted }: ManualObservationListProps): JSX.Element => {
	const { user } = useUser();

	return manualObservations === null ? (
		<Loading />
	) : (
		<Table striped bordered hover size="sm" responsive>
			<thead>
				<tr>
					<th>Navn</th>
					<th>Type</th>
					<th>Antall</th>
					<th>Antall/m²</th>
					<th>Tetthet Prosent</th>
					<th>Lengde</th>
					<th>Kommentar</th>
					<th>{/* Intentionally empty */}</th>
				</tr>
			</thead>
			<tbody>
				{manualObservations.map(manualObservation => (
					<tr key={manualObservation.id}>
						<td>
							<Link to={generatePath("/editManualObservation/:id", { id: manualObservation.id })}>
								{manualObservation.type === "life" ? (
									<LifeTypeObservationName lifeTypeId={manualObservation.typeId} />
								) : (
									<LitterTypeObservationName litterObservationId={manualObservation.typeId} />
								)}
							</Link>
						</td>
						<td>
							{manualObservation.type === "life" ? (
								<LifeTypeType lifeTypeId={manualObservation.typeId} />
							) : (
								"Søppel"
							)}
						</td>
						<td>{manualObservation.seen}</td>
						<td>{manualObservation.densityAmount}</td>
						<td>{manualObservation.densityPercent}</td>
						<td>{manualObservation.length}</td>
						<td>{manualObservation.comment}</td>
						<td>
							{user?.type === "dfh-user" && excursion?.owner === user.userId ? (
								<Button
									style={{ borderColor: "transparent" }}
									variant={"outline-danger"}
									onClick={() => {
										void (async () => {
											if (!confirm("Er du sikker på at du vil slette denne observasjonen?")) {
												return;
											}

											try {
												await Backend.deleteManualObservation(manualObservation.id);
												void onDeleted(manualObservation);
											} catch (err) {
												console.error(err);
												toast("Kunne ikke slette observasjonen", { type: "error" });
											}
										})();
									}}
								>
									<FontAwesomeIcon icon={faRemove} fixedWidth />
								</Button>
							) : null}
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
};

const LifeTypeObservationName = ({ lifeTypeId }: { lifeTypeId: LifeType["id"] }): JSX.Element => {
	const lifeType = useLifeType(lifeTypeId);
	return <>{lifeType?.species ?? "..."}</>;
};

const LifeTypeType = ({ lifeTypeId }: { lifeTypeId: LifeType["id"] }): JSX.Element => {
	const lifeType = useLifeType(lifeTypeId);

	if (lifeType === null) {
		return <>Ukjent</>;
	}

	if (lifeType.isAnimal) {
		return <>Dyr</>;
	} else {
		return <>Plante</>;
	}
};

interface LitterObservationNameProps {
	litterObservationId: ManualObservation["typeId"];
}

const LitterTypeObservationName = ({ litterObservationId }: LitterObservationNameProps): JSX.Element => {
	const litterType = useLitterType(litterObservationId);
	return <>{litterType?.name ?? "..."}</>;
};

export default ObservationList;
export { ObservationList };
