import { useEffect, useState } from "react";
import { LatLon } from "../models/LatLon";

type Ret = {
	pos: LatLon | null;
	error: string | null;
	update: () => void;
};

const useGeolocation = (initialPos: LatLon | null): Ret => {
	const [pos, setPos] = useState<LatLon | null>(initialPos);
	const [error, setError] = useState<string | null>(null);
	useEffect(() => {
		// Do nothing if we already have a position
		if (pos !== null) {
			return;
		}

		// Get the user's position
		navigator.geolocation.getCurrentPosition(
			position => {
				setPos({
					lat: position.coords.latitude,
					lon: position.coords.longitude
				});
				setError(null);
			},
			error => {
				let message: string | undefined = undefined;
				if (error.code === 1) {
					message = "Permission denied";
				} else if (error.code === 2) {
					message = "Position not available";
				} else if (error.code === 3) {
					message = "Timeout while getting position";
				} else {
					// This should never happen
					console.error("Unknown position error:", error);
					message = "Unknown position error";
				}

				setError(message);
			}
		);
	}, [pos, setPos, setError]);

	const update = () => {
		setPos(null);
		setError(null);
	};

	return { pos, error, update };
};

export default useGeolocation;
export { useGeolocation };
