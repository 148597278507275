import { LitterType } from "../../models/LitterType";
import { Row, Col } from "react-bootstrap";
import { Carousel } from "../images/Carousel";

interface ViewLitterTypeProps {
	litterType: LitterType;
}

const ViewLitterType = ({ litterType }: ViewLitterTypeProps): JSX.Element => (
	<>
		{litterType.imageTypeList.length > 0 ? (
			<Row>
				<Col>
					<Carousel images={litterType.imageTypeList} size="thumb" />
				</Col>
			</Row>
		) : null}
		<Row as="dl">
			<Col xs={4} sm={3} md={2}>
				<dt>Navn</dt>
				<dd>{litterType.name ?? null}</dd>
			</Col>
			<Col xs={4} sm={3} md={2}>
				<dt>Kode</dt>
				<dd>{litterType.code}</dd>
			</Col>
			<Col xs={4} sm={3} md={2}>
				<dt>Gruppekode</dt>
				<dd>{litterType.groupCode ?? null}</dd>
			</Col>
			<Col xs={4} sm={3} md={2}>
				<dt>Materialgruppe</dt>
				<dd>{litterType.materialGroup ?? null}</dd>
			</Col>
			<Col xs={4} sm={3} md={2}>
				<dt>Beskrivelse</dt>
				<dd>{litterType.description ?? null}</dd>
			</Col>
			<Col xs={4} sm={3} md={2}></Col>
		</Row>
	</>
);

export default ViewLitterType;
export { ViewLitterType };
