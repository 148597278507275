import { Env, envSchema } from "./models/Env";

// Validate the environment variables
// XXX Values from the environment are always strings. Need to convert them to proper types before giving them
// to the schema
// XXX Apps created with create-react-app ignore (almost)
// all environment variables not starting with "REACT_APP_". See
// https://create-react-app.dev/docs/adding-custom-environment-variables/
const env: Env = envSchema.validateSync({
	NODE_ENV: process.env.NODE_ENV,
	BACKEND_BASE_PATH: process.env.REACT_APP_BACKEND_BASE_PATH,
	FRONTPAGE_BASE_URL: process.env.REACT_APP_FRONTPAGE_BASE_URL,
	ACCOUNTS_BASE_URL: process.env.REACT_APP_ACCOUNTS_BASE_URL,
	HAVFUNN_BASE_URL: process.env.REACT_APP_HAVFUNN_BASE_URL
});

export default env;
export { env };
