import Loading from "../application/Loading";
import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../../hooks/useUser";

export type RequireLoginProps = PropsWithChildren<{
	userType?: "dfh-user" | "participant";
}>;

const RequireLogin = ({ userType, children }: RequireLoginProps): JSX.Element => {
	const { loaded, user } = useUser();

	const redirect = () => {
		if (!loaded) {
			return <Loading />;
		} else {
			return <Navigate to="/" />;
		}
	};

	// If the user is not logged in at all
	if (user === null || user === undefined) {
		return redirect();
	}

	// If the user is not the correct type of user
	if (userType !== undefined && user.type !== userType) {
		return redirect();
	}

	return <>{children}</>;
};

export default RequireLogin;
export { RequireLogin };
