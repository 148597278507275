import { Image as ImageType } from "../../models/Image";
import env from "../../env";

type ImgProps = JSX.IntrinsicElements["img"]; //
interface ImageProps extends ImgProps {
	image: ImageType;
	size: "orig" | "thumb";
}

// The images are served from a proxy on the test/staging/prod environments. It will be a lot of unnecessary config to
// make them available in the dev env, or through backend. Using this hack to make it dead simple
const imgDomain = env.NODE_ENV === "development" ? "https://ekskursjon-test.dugnadforhavet.no" : "";

const Image = ({ image, size, ...imgProps }: ImageProps): JSX.Element => {
	// Make sure the
	const className = `w-100 ${imgProps.className ?? ""}`.trim();
	const modifiedImgProps = { ...imgProps, className };

	return <img src={`${imgDomain}/img/${size}/${image.imagePath}`} {...modifiedImgProps} />;
};

export default Image;
export { Image };
