import * as yup from "yup";
import { BaseType, baseTypeSchema } from "./BaseType";
import { Image, imageSchema } from "./Image";

export interface LifeType extends BaseType {
	species?: string;
	genus: string | null;
	family: string | null;
	bioOrder: string | null; // 'order' is a reserved word in SQL
	bioClass: string | null; // 'class' is a reserved word in JS
	phylum: string | null;
	tsn: number | null;
	aphiaId: number | null;
	registerSeen: boolean;
	registerDensityAmount: boolean;
	registerDensityPercent: boolean;
	registerLength: boolean;
	registerSex: boolean;
	isAnimal: boolean;
	comment: string | null;
	imageTypeList: Image[];
}

export const lifeTypeSchema = baseTypeSchema.shape({
	species: yup.string(),
	genus: yup.string().nullable(),
	family: yup.string().nullable(),
	bioOrder: yup.string().nullable(), // 'order' is a reserved word in SQL
	bioClass: yup.string().nullable(), // 'class' is a reserved word in Java and JS
	phylum: yup.string().nullable(),
	tsn: yup.number().nullable(),
	aphiaId: yup.number().nullable(),
	registerSeen: yup.boolean().default(false),
	registerDensityAmount: yup.boolean().default(false),
	registerDensityPercent: yup.boolean().default(false),
	registerLength: yup.boolean().default(false),
	registerSex: yup.boolean().default(false),
	isAnimal: yup.boolean().required(),
	comment: yup.string().nullable(),
	imageTypeList: yup.array(imageSchema.required()).required()
});

lifeTypeSchema as yup.SchemaOf<LifeType>;
