import * as yup from "yup";

export interface LoginCredentials {
	email: string;
	password: string;
}

export const loginCredentialsSchema = yup.object({
	email: yup.string().required(),
	password: yup.string().required()
});

// Make sure the schema actually describes the type. This line will cause compilation errors if they do not match
loginCredentialsSchema as yup.SchemaOf<LoginCredentials>;
