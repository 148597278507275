import translation from "./translation";
import frontpage from "./frontpage";
import application from "./application";

const nb = {
	translation,
	frontpage,
	application
} as const;

export default nb;
