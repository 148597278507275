import * as yup from "yup";

/*****************************************************************************
 * This model descibes the structure of the environment variables object the *
 * application is using.                                                     *
 *****************************************************************************/

export interface Env {
	NODE_ENV: "development" | "test" | "production";
	BACKEND_BASE_PATH: string;
	FRONTPAGE_BASE_URL: string;
	ACCOUNTS_BASE_URL: string;
	HAVFUNN_BASE_URL: string;
}

/** Schema describing the environment object */
export const envSchema = yup.object({
	NODE_ENV: yup.mixed().oneOf(["development", "test", "production"]).required(),
	BACKEND_BASE_PATH: yup.string().required(),
	FRONTPAGE_BASE_URL: yup.string().url().required(),
	ACCOUNTS_BASE_URL: yup.string().url().required(),
	HAVFUNN_BASE_URL: yup.string().url().required()
});

envSchema as yup.SchemaOf<Env>;
