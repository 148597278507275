import { Button, Table } from "react-bootstrap";
import { Station } from "../../models/Station";
import { Excursion } from "../../models/Excursion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import * as Backend from "../../models/Backend";
import { toast } from "react-toastify";
import { formatToUTC } from "../../utils/date";
import { Link, generatePath } from "react-router-dom";
import { useUser } from "../../hooks/useUser";

interface StationListProps {
	excursion: Excursion;
	stations: Station[];
	onDeleted: (station: Station) => void | Promise<void>;
}

const StationList = ({ excursion, stations, onDeleted }: StationListProps): JSX.Element => {
	const { user } = useUser();

	return (
		<Table responsive hover>
			<thead>
				<tr>
					<th>Stasjonsnavn</th>
					<th>Stasjonsnummer</th>
					<th>Dato</th>
				</tr>
			</thead>
			<tbody>
				{stations.map(station => (
					<tr key={station.id}>
						<td align="left">
							<Link to={generatePath("/viewStation/:id", { id: station.id })}>{station.name}</Link>
						</td>
						<td align="left">{station.number}</td>
						<td align="left">{station.dateTime === null ? "" : formatToUTC(station.dateTime)}</td>
						<td align="center">
							{user?.type === "dfh-user" && excursion.owner === user.userId ? (
								<Button
									style={{ borderColor: "transparent" }}
									variant={"outline-danger"}
									onClick={() => {
										void (async () => {
											if (!confirm("Er du sikker på at du vil slette denne stasjonen?")) {
												return;
											}

											try {
												await Backend.deleteStation(station.id);
												void onDeleted(station);
											} catch (err) {
												console.error(err);
												toast("Kunne ikke slette stasjonen", { type: "error" });
											}
										})();
									}}
								>
									<FontAwesomeIcon icon={faRemove} fixedWidth />
								</Button>
							) : null}
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
};

export default StationList;
export { StationList };
