import Loading from "../application/Loading";
import { LifeType } from "../../models/LifeType";
import { LitterType } from "../../models/LitterType";
import { useLifeTypes } from "../../hooks/useLifeTypes";
import { useLitterTypes } from "../../hooks/useLitterTypes";
import Select from "react-select";

export type SelectValue = {
	type: "life" | "litter";
	id: LifeType["id"] | LitterType["id"];
};

type RawObservationTypeSelectorProps = {
	lifeTypes: LifeType[];
	litterTypes: LitterType[];
	onSelect: (value: SelectValue | null) => void | Promise<void>;
};

const RawObservationTypeSelector = ({
	lifeTypes,
	litterTypes,
	onSelect
}: RawObservationTypeSelectorProps): JSX.Element => {
	// Prepare the options for the selector
	const options: { label: string; value: SelectValue }[] = [
		...lifeTypes.map(lt => ({
			label: `${lt.species ?? ""} (${lt.isAnimal ? "Dyr" : "Plante"})`,
			value: { type: "life", id: lt.id } as SelectValue
		})),
		...litterTypes.map(lt => ({
			label: `${lt.name ?? ""} (Søppel)`,
			value: { type: "litter", id: lt.id } as SelectValue
		}))
	]
		// Sort them by label
		.sort((a, b) => {
			if (a.label > b.label) {
				return 1;
			} else if (a.label < b.label) {
				return -1;
			}
			return 0;
		});

	return <Select options={options} onChange={option => (option !== null ? void onSelect(option.value) : null)} />;
};

type ObservationTypeSelectorProps = Pick<RawObservationTypeSelectorProps, "onSelect">;

const ObservationTypeSelector = ({ onSelect }: ObservationTypeSelectorProps): JSX.Element => {
	const lifeTypes = useLifeTypes();
	const litterTypes = useLitterTypes();

	return lifeTypes === null || litterTypes === null ? (
		<Loading />
	) : (
		<RawObservationTypeSelector lifeTypes={lifeTypes} litterTypes={litterTypes} onSelect={onSelect} />
	);
};

export default ObservationTypeSelector;
export { RawObservationTypeSelector, ObservationTypeSelector };
