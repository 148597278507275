import Loading from "../application/Loading";
import { Area } from "../../models/Area";
import * as Backend from "../../models/Backend";
import { generatePath, useNavigate } from "react-router-dom";
import { useAreas } from "../../hooks/useAreas";
import ExcursionForm from "../excursion/ExcursionForm";
import { toast } from "react-toastify";
import { StandardLayout } from "../layouts/StandardLayout";

interface RawCreateExcursionProps {
	areas: Area[];
}

const RawCreateExcursion = ({ areas }: RawCreateExcursionProps): JSX.Element | null => {
	const navigate = useNavigate();

	return (
		<StandardLayout header="Opprett ekskursjon">
			<ExcursionForm
				areas={areas}
				onSubmit={async excursion => {
					// Send it to backend
					try {
						const { id } = await Backend.createExcursion(excursion);

						// Navigate to the newly created excursion
						navigate(generatePath("/excursion/:id", { id }));
					} catch (err) {
						console.error(err);

						toast("Noe gikk galt med opprettelsen av ekskursjonen. Prøv igjen.", { type: "error" });
					}
				}}
			/>
		</StandardLayout>
	);
};

const CreateExcursion = (): JSX.Element | null => {
	const areas = useAreas();

	return areas === null ? <Loading /> : <RawCreateExcursion areas={areas} />;
};

export default CreateExcursion;
export { CreateExcursion, RawCreateExcursion };
