import Loading from "../application/Loading";
import { Area } from "../../models/Area";
import * as Backend from "../../models/Backend";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useAreas } from "../../hooks/useAreas";
import ExcursionForm from "../excursion/ExcursionForm";
import { toast } from "react-toastify";
import { Excursion } from "../../models/Excursion";
import useExcursion from "../../hooks/useExcursion";
import { StandardLayout } from "../layouts/StandardLayout";

interface RawEditExcursionProps {
	excursion: Excursion;
	areas: Area[];
}

const RawEditExcursion = ({ excursion, areas }: RawEditExcursionProps): JSX.Element | null => {
	const navigate = useNavigate();

	return (
		<StandardLayout header="Rediger ekskursjon">
			<h1>Rediger ekskursjon</h1>

			<ExcursionForm
				excursion={excursion}
				areas={areas}
				onSubmit={async values => {
					const modifiedExcursion = {
						...values,
						id: excursion.id
					};

					try {
						// Send it to backend
						await Backend.editExcursion(modifiedExcursion);

						// Navigate to the excursion
						navigate(generatePath("/excursion/:id", { id: excursion.id }));
					} catch (err) {
						console.error(err);

						toast("Noe gikk galt med redigeringen av ekskursjonen. Prøv igjen.");
					}
				}}
			/>
		</StandardLayout>
	);
};

const EditExcursion = (): JSX.Element | null => {
	const areas = useAreas();
	const { excursionId } = useParams<"excursionId">();
	const { excursion } = useExcursion(excursionId as string);

	return areas === null || excursion === null ? (
		<Loading />
	) : (
		<RawEditExcursion excursion={excursion} areas={areas} />
	);
};

export default EditExcursion;
export { EditExcursion, RawEditExcursion };
