import { Button, FormControl, FormGroup } from "react-bootstrap";
import * as yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";

export type PinFormProps = {
	onSubmit: (pin: number) => void | Promise<void>;
};

const PinForm = ({ onSubmit }: PinFormProps): JSX.Element => {
	const schema = yup.object({
		pin: yup.number().min(100_000).max(999_999).required()
	});

	return (
		<Formik
			initialValues={{ pin: "" }}
			validationSchema={schema}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);

				const pin = Number(values.pin);

				try {
					await onSubmit(pin);
				} catch (err) {
					console.error(err);
				} finally {
					setSubmitting(false);
				}
			}}
		>
			{({ isSubmitting }) => (
				<Form>
					<FormGroup className="mb-3">
						<Field as={FormControl} placeholder="PIN" type={"number"} name="pin" />
						<ErrorMessage component={"p"} className="text-danger" name="pin" />
					</FormGroup>
					<Button variant="success" className="w-100 text-uppercase" type="submit" disabled={isSubmitting}>
						Gå til ekskursjon
					</Button>
				</Form>
			)}
		</Formik>
	);
};

export default PinForm;
export { PinForm };
