import * as yup from "yup";
import { BaseType, baseTypeSchema } from "./BaseType";
import { LifeType } from "./LifeType";
import { LitterType } from "./LitterType";
import { Participant } from "./Participant";
import { Station } from "./Station";

export interface ManualObservation extends BaseType {
	stationId: Station["id"];
	participantId: Participant["id"] | null;
	type: "life" | "litter";
	typeId: LifeType["id"] | LitterType["id"];
	seen: number | null;
	densityAmount: number | null;
	densityPercent: number | null;
	length: number | null;
	sex: string | null;
	comment: string | null;
}

export const manualObservationSchema = baseTypeSchema.shape({
	stationId: yup.string().required(),
	participantId: yup.string().required().nullable(),
	type: yup.string().oneOf(["life", "litter"]).required(),
	typeId: yup.string().required(),
	seen: yup.number().nullable(),
	densityAmount: yup.number().nullable(),
	densityPercent: yup.number().nullable(),
	length: yup.number().nullable(),
	sex: yup.string().nullable(),
	comment: yup.string().nullable()
});

manualObservationSchema as yup.SchemaOf<ManualObservation>;
