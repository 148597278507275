import { useState, useEffect } from "react";
import { Area } from "../models/Area";
import * as Backend from "../models/Backend";

// Cache of the existing areas
let areaCache: Area[] | null = null;

/** Loads areas from backend, caching them for reuse. Returns null until they are fetched */
const useAreas = (): Area[] | null => {
	const [areas, setAreas] = useState(areaCache);
	useEffect(() => {
		void (async () => {
			const areas = await Backend.areas();
			areaCache = areas;
			setAreas(areas);
		})();
	}, []);

	return areas;
};

export default useAreas;
export { useAreas };
