import { LitterType, litterTypeSchema } from "../LitterType";
import { Instrument, instrumentSchema } from "../Instrument";
import { BottomType, bottomTypeSchema } from "../BottomType";
import { Area, areaSchema } from "../Area";
import { Excursion, excursionSchema } from "../Excursion";
import { LoginCredentials } from "../LoginCredentials";
import { createJSONFetcher } from "../../fetchers/jsonFetcher";
import { Station, stationSchema } from "../Station";
import { InstrumentObservation, instrumentObservationSchema } from "../InstrumentObservation";
import { ManualObservation, manualObservationSchema } from "../ManualObservation";
import { LifeType, lifeTypeSchema } from "../LifeType";
import { DFHUser, ParticipantUser, RawUser, rawUserSchema, User } from "../User";
import queryString from "query-string";
import env from "../../env";
import * as yup from "yup";

/**
 * Logs the user in to the application using DFH auth
 *
 * @param credentials Object containing the user's email and password
 * @returns True is the user was successfully logged in, false otherwise
 */
const login = async (credentials: LoginCredentials): Promise<boolean> => {
	// Send the login request
	const loginRes = await fetch(`${env.BACKEND_BASE_PATH}/login`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(credentials)
	});

	if (!loginRes.redirected) {
		// TODO Proper error message
		throw new Error("Not redirected");
	}

	return loginRes.url.endsWith("/userid");
};

/**  Logout test */
const logout = async (): Promise<void> => {
	// Send the logout request
	const logoutRes = await fetch(`${env.BACKEND_BASE_PATH}/logout`);

	if (!logoutRes.redirected) {
		// TODO Proper error message
		throw new Error("Not redirected");
	}
};

/** Logs a user in as a participant using a pin */
const loginWithPin = async (pin: NonNullable<Excursion["pin"]>): Promise<Excursion> => {
	const excursion = (await createJSONFetcher(excursionSchema)(
		`${env.BACKEND_BASE_PATH}/excursion/byPin/${pin}`
	)) as Excursion;

	const loginRes = await fetch(`${env.BACKEND_BASE_PATH}/login`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify({
			pin: String(pin),
			excursionId: excursion.id
		})
	});

	if (!loginRes.redirected || !loginRes.url.endsWith("/userid")) {
		throw new Error("Invalid pin");
	}

	return excursion;
};

/** Fetches all available litter types from backend */
const litterTypes = (): Promise<LitterType[]> => {
	const litterTypeListSchema = yup.array(litterTypeSchema.required()).required() as yup.SchemaOf<LitterType[]>;
	const litterTypesFetcher = createJSONFetcher<LitterType[]>(litterTypeListSchema);

	return litterTypesFetcher(`${env.BACKEND_BASE_PATH}/litterTypes`);
};

/** Fetches all available life types from backend */
const lifeTypes = (): Promise<LifeType[]> => {
	const lifeTypeListSchema = yup.array(lifeTypeSchema.required()).required() as yup.SchemaOf<LifeType[]>;
	const litterTypesFetcher = createJSONFetcher<LifeType[]>(lifeTypeListSchema);

	return litterTypesFetcher(`${env.BACKEND_BASE_PATH}/lifeTypes`);
};

/** Fetches all available areas from backend */
const areas = (): Promise<Area[]> => {
	const areaListSchema = yup.array(areaSchema.required()).required() as yup.SchemaOf<Area[]>;
	const areaListFetcher = createJSONFetcher<Area[]>(areaListSchema);

	return areaListFetcher(`${env.BACKEND_BASE_PATH}/areas`);
};

/** Creates a new area */
const createArea = (name: Area["name"]): Promise<Area> => {
	const newAreaFetcher = createJSONFetcher<Area>(areaSchema);

	return newAreaFetcher(`${env.BACKEND_BASE_PATH}/areas/create`, {
		method: "POST",
		headers: {
			"Content-Type": "text/plain"
		},
		body: name
	});
};

/** Gets an area by ID */
const getArea = (id: Area["id"]): Promise<Area> => {
	const areaFetcher = createJSONFetcher<Area>(areaSchema);

	return areaFetcher(`${env.BACKEND_BASE_PATH}/areas/${id}`);
};

/** Fetches all available instruments from backend */
const instruments = async (): Promise<Instrument[]> => {
	const instrumentListSchema = yup.array(instrumentSchema.required()).required() as yup.SchemaOf<Instrument[]>;
	const instrumentListFetcher = createJSONFetcher<Instrument[]>(instrumentListSchema);

	return instrumentListFetcher(`${env.BACKEND_BASE_PATH}/instruments`);
};

/** Fetches all available bottom types from backend */
const bottomTypes = async (): Promise<BottomType[]> => {
	const bottomTypeListSchema = yup.array(bottomTypeSchema.required()).required() as yup.SchemaOf<BottomType[]>;
	const bottomTypeListFetcher = createJSONFetcher<BottomType[]>(bottomTypeListSchema);

	return bottomTypeListFetcher(`${env.BACKEND_BASE_PATH}/bottomTypes`);
};

type InputExcursion = Omit<Excursion, "id" | "createdAt" | "updatedAt" | "owner" | "ownername" | "pin">;
/**
 * Sends a request to backend to create an excursion
 *
 * @param excursion data needed to create an excursion
 * @returns the newly created excursion
 */
const createExcursion = (excursion: InputExcursion): Promise<Excursion> => {
	return createJSONFetcher(excursionSchema)(`${env.BACKEND_BASE_PATH}/excursion/create`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(excursion)
	}) as Promise<Excursion>;
};

type EditExcursion = Omit<Excursion, "createdAt" | "updatedAt" | "owner" | "ownername" | "pin" | "date">;

const editExcursion = (excursion: EditExcursion): Promise<Excursion> => {
	return createJSONFetcher(excursionSchema)(`${env.BACKEND_BASE_PATH}/excursion/${excursion.id}`, {
		method: "PATCH",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(excursion)
	}) as Promise<Excursion>;
};

const getExcursionById = (id: Excursion["id"]): Promise<Excursion> => {
	return createJSONFetcher(excursionSchema)(`${env.BACKEND_BASE_PATH}/excursion/${id}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	}) as Promise<Excursion>;
};

interface GetExcursionsOptions {
	after?: Date;
	before?: Date;
	mine?: boolean;
}

const getExcursions = (options: GetExcursionsOptions = {}): Promise<Excursion[]> => {
	const schema = yup.array(excursionSchema.required()).required() as yup.SchemaOf<Excursion[]>;
	const preparedParams = {
		after: options.after?.toISOString(),
		before: options.before?.toISOString(),
		mine: options.mine
	};
	return createJSONFetcher<Excursion[]>(schema)(
		`${env.BACKEND_BASE_PATH}/excursions?${queryString.stringify(preparedParams)}`
	);
};

const getExcursionByPin = (pin: string, name: string): Promise<Excursion> => {
	const schema = excursionSchema.required() as yup.SchemaOf<Excursion>;
	const preparedParams = { name };
	return createJSONFetcher<Excursion>(schema)(
		`${env.BACKEND_BASE_PATH}/excursionByPin/${pin}?${queryString.stringify(preparedParams)}`,
		{
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		}
	);
};

const virtualExcursion = (id: Excursion["id"], virtual: boolean): Promise<boolean> => {
	const schema = yup.boolean().required() as yup.SchemaOf<boolean>;
	return createJSONFetcher<boolean>(schema)(`${env.BACKEND_BASE_PATH}/excursion/${id}/setVirtual`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(virtual)
	});
};

const deleteExcursion = (id: Excursion["id"]): Promise<boolean> => {
	const schema = yup.boolean().required() as yup.SchemaOf<boolean>;
	return createJSONFetcher<boolean>(schema)(`${env.BACKEND_BASE_PATH}/excursion/${id}`, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json"
		}
	});
};

/** the following function should be updated */
const getMyExcursions = (): Promise<Excursion[]> => {
	const excursionListSchema = yup.array(excursionSchema.required()).required() as yup.SchemaOf<Excursion[]>;
	return createJSONFetcher<Excursion[]>(excursionListSchema)(`${env.BACKEND_BASE_PATH}/excursions/mine`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	});
};

/** Omits (Removes) the values from Station object we dont want to include */
type InputStation = Omit<Station, "id" | "createdAt" | "updatedAt">;

const createStation = (station: InputStation): Promise<Station> => {
	// Send the createStation request
	return createJSONFetcher(stationSchema)(`${env.BACKEND_BASE_PATH}/station/create`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(station)
	}) as Promise<Station>;
};

type EditStation = Omit<Station, "createdAt" | "updatedAt">;

const editStation = (station: EditStation): Promise<Station> => {
	return createJSONFetcher(stationSchema)(`${env.BACKEND_BASE_PATH}/station/${station.id}`, {
		method: "PATCH",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(station)
	}) as Promise<Station>;
};

const getStations = (excursionId: Station["excursionId"]): Promise<Station[]> => {
	const preparedParams = { excursionId };
	const stationListSchema = yup.array(stationSchema.required()).required() as yup.SchemaOf<Station[]>;
	return createJSONFetcher<Station[]>(stationListSchema)(
		`${env.BACKEND_BASE_PATH}/stationList?${queryString.stringify(preparedParams)}`,
		{
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		}
	);
};

const getStation = (id: Station["id"]): Promise<Station> => {
	return createJSONFetcher(stationSchema)(`${env.BACKEND_BASE_PATH}/station/${id}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	}) as Promise<Station>;
};

const deleteStation = (id: Station["id"]): Promise<boolean> => {
	const schema = yup.boolean().required() as yup.SchemaOf<boolean>;
	return createJSONFetcher<boolean>(schema)(`${env.BACKEND_BASE_PATH}/station/${id}`, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json"
		}
	});
};

type InputInstrumentObservation = Omit<InstrumentObservation, "id" | "createdAt" | "updatedAt">;

const createInstrumentObservation = (observation: InputInstrumentObservation): Promise<string> => {
	const schema = yup.string().required() as yup.SchemaOf<string>;
	return createJSONFetcher<string>(schema)(`${env.BACKEND_BASE_PATH}/createInstrumentObservation`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(observation)
	});
};

type EditInstrumentObservation = Omit<InstrumentObservation, "createdAt" | "updatedAt">;

const editInstrumentObservation = (observation: EditInstrumentObservation): Promise<boolean> => {
	const schema = yup.boolean().required() as yup.SchemaOf<boolean>;
	return createJSONFetcher<boolean>(schema)(`${env.BACKEND_BASE_PATH}/editInstrumentObservation`, {
		method: "PATCH",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(observation)
	});
};

const getInstrumentObservations = (stationId: InstrumentObservation["stationId"]): Promise<InstrumentObservation[]> => {
	const preparedParams = { stationId };
	const instrumentObservationListSchema = yup
		.array(instrumentObservationSchema.required())
		.required() as yup.SchemaOf<InstrumentObservation[]>;

	return createJSONFetcher<InstrumentObservation[]>(instrumentObservationListSchema)(
		`${env.BACKEND_BASE_PATH}/instrumentObservations?${queryString.stringify(preparedParams)}`,
		{
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		}
	);
};

const getInstrumentObservation = (id: InstrumentObservation["id"]): Promise<InstrumentObservation> => {
	const preparedParams = { id };
	return createJSONFetcher(instrumentObservationSchema)(
		`${env.BACKEND_BASE_PATH}/instrumentObservation?${queryString.stringify(preparedParams)}`,
		{
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		}
	) as Promise<InstrumentObservation>;
};

const deleteInstrumentObservation = (id: InstrumentObservation["id"]): Promise<boolean> => {
	const preparedParams = { id };
	const schema = yup.boolean().required() as yup.SchemaOf<boolean>;
	return createJSONFetcher<boolean>(schema)(
		`${env.BACKEND_BASE_PATH}/instrumentObservation?${queryString.stringify(preparedParams)}`,
		{
			method: "DELETE",
			headers: {
				"Content-Type": "application/json"
			}
		}
	);
};

type InputManualObservation = Omit<ManualObservation, "id" | "participantId" | "createdAt" | "updatedAt">;

const createManualObservation = (observation: InputManualObservation): Promise<ManualObservation> => {
	return createJSONFetcher(manualObservationSchema)(`${env.BACKEND_BASE_PATH}/createManualObservation`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(observation)
	}) as Promise<ManualObservation>;
};

type EditManualObservation = Omit<ManualObservation, "createdAt" | "updatedAt">;

const editManualObservation = (observation: EditManualObservation): Promise<boolean> => {
	const schema = yup.boolean().required() as yup.SchemaOf<boolean>;
	return createJSONFetcher<boolean>(schema)(`${env.BACKEND_BASE_PATH}/editManualObservation`, {
		method: "PATCH",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(observation)
	});
};

const getManualObservations = (stationId: ManualObservation["stationId"]): Promise<ManualObservation[]> => {
	const preparedParams = { stationId };
	const manualObservationListSchema = yup.array(manualObservationSchema.required()).required() as yup.SchemaOf<
		ManualObservation[]
	>;
	return createJSONFetcher<ManualObservation[]>(manualObservationListSchema)(
		`${env.BACKEND_BASE_PATH}/manualObservations?${queryString.stringify(preparedParams)}`,
		{
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		}
	);
};

const getManualObservation = (id: ManualObservation["id"]): Promise<ManualObservation> => {
	const preparedParams = { id };
	return createJSONFetcher(manualObservationSchema)(
		`${env.BACKEND_BASE_PATH}/manualObservation?${queryString.stringify(preparedParams)}`,
		{
			method: "GET",
			headers: {
				"Content-Type": "application/json"
			}
		}
	) as Promise<ManualObservation>;
};

const deleteManualObservation = (id: ManualObservation["id"]): Promise<boolean> => {
	const preparedParams = { id };
	const schema = yup.boolean().required() as yup.SchemaOf<boolean>;
	return createJSONFetcher<boolean>(schema)(
		`${env.BACKEND_BASE_PATH}/manualObservation?${queryString.stringify(preparedParams)}`,
		{
			method: "DELETE",
			headers: {
				"Content-Type": "application/json"
			}
		}
	);
};

const userId = async (): Promise<User | null> => {
	try {
		const rawUser = await createJSONFetcher<RawUser>(rawUserSchema)(`${env.BACKEND_BASE_PATH}/userid`);

		return rawUser.userId !== null
			? ({
					type: "dfh-user",
					userId: rawUser.userId,
					name: rawUser.ownername
			  } as DFHUser)
			: ({
					type: "participant",
					participantId: rawUser.participantId,
					name: rawUser.name,
					excursionId: rawUser.excursionId
			  } as ParticipantUser);
	} catch (err) {
		console.error(err);
		return null;
	}
};

export {
	login,
	logout,
	loginWithPin,
	litterTypes,
	lifeTypes,
	areas,
	createArea,
	getArea,
	instruments,
	bottomTypes,
	createExcursion,
	editExcursion,
	getExcursions,
	getExcursionById,
	getExcursionByPin,
	virtualExcursion,
	deleteExcursion,
	getMyExcursions,
	createStation,
	editStation,
	getStations,
	getStation,
	deleteStation,
	createInstrumentObservation,
	editInstrumentObservation,
	getInstrumentObservations,
	getInstrumentObservation,
	deleteInstrumentObservation,
	createManualObservation,
	editManualObservation,
	getManualObservations,
	getManualObservation,
	deleteManualObservation,
	userId
};
