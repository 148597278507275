import * as yup from "yup";
import { BaseType, baseTypeSchema } from "./BaseType";

export interface BottomType extends BaseType {
	name: string;
	description: string | null;
	size: string | null;
}

export const bottomTypeSchema = baseTypeSchema.shape({
	name: yup.string(),
	description: yup.string().nullable(),
	size: yup.string().nullable()
});

bottomTypeSchema as yup.SchemaOf<BottomType>;
