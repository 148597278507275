import { useRef } from "react";
import env from "../env";

/** Gives a random base ID when not in test, and a deterministic one in test */
const useBaseId = (): string => {
	const initialValue = env.NODE_ENV === "test" ? "test" : Math.random().toString().slice(2);
	const { current: baseId } = useRef(initialValue);

	return baseId;
};

export default useBaseId;
export { useBaseId };
