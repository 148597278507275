import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap";
import { ObservationTypeSelector, SelectValue } from "./ObservationTypeSelector";

type ModalProps = Parameters<typeof Modal>[0];
interface ObservationTypeSelectorModalProps extends ModalProps {
	handleSelected: (value: SelectValue) => void;
}
const ObservationTypeSelectorModal = ({
	handleSelected,
	...modalParams
}: ObservationTypeSelectorModalProps): JSX.Element => {
	const [value, setValue] = useState<SelectValue | null>(null);

	return (
		<Modal {...modalParams}>
			<ModalHeader closeButton>
				<ModalTitle>Velg observasjonstype</ModalTitle>
			</ModalHeader>

			<ModalBody>
				<ObservationTypeSelector onSelect={value => setValue(value)} />
			</ModalBody>

			<ModalFooter>
				<Button
					variant="primary"
					onClick={() => (value === null ? null : handleSelected(value))}
					disabled={value === null}
				>
					Opprett observasjon
				</Button>
				<Button variant="secondary" onClick={modalParams.onHide}>
					Lukk
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ObservationTypeSelectorModal;
export { ObservationTypeSelectorModal };
