import { Excursion } from "../models/Excursion";
import { useState, useEffect } from "react";
import * as Backend from "../models/Backend";

const useExcursion = (excursionId: Excursion["id"]): { loading: boolean; excursion: Excursion | null } => {
	const [loading, setLoading] = useState(true);
	const [excursion, setExcursion] = useState<Excursion | null>(null);

	useEffect(() => {
		void (async () => {
			setLoading(true);

			const excursion = await Backend.getExcursionById(excursionId);

			setExcursion(excursion);
			setLoading(false);
		})();
	}, [excursionId]);

	return { loading, excursion };
};

export default useExcursion;
export { useExcursion };
