import { useState, useEffect } from "react";
import { LitterType } from "../models/LitterType";
import * as Backend from "../models/Backend";

// Cache of the existing litterTypes
let litterTypeCache: LitterType[] | null = null;

/** Loads litterTypes from backend, caching them for reuse. Returns null until they are fetched */
const useLitterTypes = (): LitterType[] | null => {
	const [litterTypes, setLitterTypes] = useState(litterTypeCache);
	useEffect(() => {
		void (async () => {
			const litterTypes = await Backend.litterTypes();
			litterTypeCache = litterTypes;
			setLitterTypes(litterTypes);
		})();
	}, []);

	return litterTypes;
};

export default useLitterTypes;
export { useLitterTypes };
