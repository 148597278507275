import * as yup from "yup";
import { BaseType, baseTypeSchema } from "./BaseType";
import { Instrument } from "./Instrument";
import { Participant } from "./Participant";
import { Station } from "./Station";

export interface InstrumentObservation extends BaseType {
	stationId: Station["id"];
	participantId: Participant["id"];
	instrumentId: Instrument["id"];
	comment: string | null;
}

export const instrumentObservationSchema = baseTypeSchema.shape({
	stationId: yup.string().required(),
	participantId: yup.string().required(),
	instrumentId: yup.string().required(),
	comment: yup.string().nullable()
});

instrumentObservationSchema as yup.SchemaOf<InstrumentObservation>;
