import Loading from "../application/Loading";
import { LitterType } from "../../models/LitterType";
import { LifeType } from "../../models/LifeType";
import { Station } from "../../models/Station";
import ManualObservationForm from "../manualObservation/ManualObservationForm";
import useLitterType from "../../hooks/useLitterType";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import useLifeType from "../../hooks/useLifeType";
import * as Backend from "../../models/Backend";
import { toast } from "react-toastify";
import ViewLitterType from "../litterType/ViewLitterType";
import ViewLifeType from "../lifeType/ViewLifeType";
import StandardLayout from "../layouts/StandardLayout";

interface RawCreateManualObservationProps {
	observationType: LitterType | LifeType;
	typeName: "litter" | "life";
	stationId: Station["id"];
}

const RawCreateManualObservation = ({
	observationType,
	typeName,
	stationId
}: RawCreateManualObservationProps): JSX.Element => {
	const navigate = useNavigate();

	// Figure out what fields to show in the form
	const formFields = {
		registerSeen: observationType.registerSeen ?? false,
		registerDensityAmount: observationType.registerDensityAmount ?? false,
		registerDensityPercent: observationType.registerDensityPercent ?? false,
		registerLength: observationType.registerLength ?? false,
		registerSex: (observationType as { registerSex?: boolean }).registerSex ?? false
	};

	return (
		<StandardLayout header="Ny observasjon">
			{typeName === "litter" ? (
				<ViewLitterType litterType={observationType as LitterType} />
			) : (
				<ViewLifeType lifeType={observationType as LifeType} />
			)}

			<ManualObservationForm
				fields={formFields}
				onSubmit={async data => {
					try {
						const observation = {
							...data,
							stationId,
							type: typeName,
							typeId: observationType.id
						};

						await Backend.createManualObservation(observation);

						navigate(generatePath("/viewStation/:stationId", { stationId }));
					} catch (err) {
						console.error(err);

						toast("Noe gikk galt med opprettelsen av observasjonen", { type: "error" });
					}
				}}
			/>
		</StandardLayout>
	);
};

interface CreateManualObservationProps {
	useObservationType: ((id: LitterType["id"]) => LitterType | null) | ((id: LifeType["id"]) => LifeType | null);
	typeName: RawCreateManualObservationProps["typeName"];
}

const CreateManualObservation = ({ useObservationType, typeName }: CreateManualObservationProps): JSX.Element => {
	const { observationTypeId, stationId } = useParams<"observationTypeId" | "stationId">();
	if (observationTypeId === undefined) {
		throw new Error("Need an observation type ID param from router");
	}
	if (stationId === undefined) {
		throw new Error("Need station ID param from router");
	}

	const observationType = useObservationType(observationTypeId);

	return observationType === null ? (
		<Loading />
	) : (
		<RawCreateManualObservation observationType={observationType} typeName={typeName} stationId={stationId} />
	);
};

const CreateLitterObseration = (): JSX.Element => (
	<CreateManualObservation useObservationType={useLitterType} typeName="litter" />
);
const CreateLifeObservation = (): JSX.Element => (
	<CreateManualObservation useObservationType={useLifeType} typeName="life" />
);

export { CreateLitterObseration, CreateLifeObservation, RawCreateManualObservation };
