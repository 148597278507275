import * as yup from "yup";
import { BaseType, baseTypeSchema } from "./BaseType";

export interface Instrument extends BaseType {
	name: string;
}

export const instrumentSchema = baseTypeSchema.shape({
	name: yup.string().required()
});

instrumentSchema as yup.SchemaOf<Instrument>;
