import { ManualObservation } from "../../models/ManualObservation";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { useBaseId } from "../../hooks/useBaseId";
import * as yup from "yup";
import { FormControl, FormFloating, FormLabel, FormSelect, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export type ManualObservationFormData = Pick<
	ManualObservation,
	"seen" | "densityAmount" | "densityPercent" | "length" | "sex" | "comment"
>;

export interface ManualObservationFormProps {
	/** The observation to edit, if any */
	manualObservation?: ManualObservation;
	/** Data about what is being observed */
	fields: {
		registerSeen: boolean;
		registerDensityAmount: boolean;
		registerDensityPercent: boolean;
		registerLength: boolean;
		registerSex: boolean;
	};
	/** Handler for the form's submit event */
	onSubmit: (formData: ManualObservationFormData) => void | Promise<void>;
}

const sexes = new Set(["Hann", "Hunn", "Tvekjønn", "Intetkjønn"]);

const ManualObservationForm = ({ manualObservation, fields, onSubmit }: ManualObservationFormProps): JSX.Element => {
	const fieldBaseId = useBaseId();
	const navigate = useNavigate();

	const schema = yup.object({
		seen: yup.number().integer().positive(),
		densityAmount: yup.number().positive(),
		densityPercent: yup.number().positive(),
		length: yup.number().positive(),
		sex: yup.string(),
		comment: yup.string()
	});

	const initialValues =
		manualObservation === undefined
			? {
					seen: "",
					densityAmount: "",
					densityPercent: "",
					length: "",
					sex: "",
					comment: ""
			  }
			: {
					seen: String(manualObservation.seen ?? ""),
					densityAmount: String(manualObservation.densityAmount ?? ""),
					densityPercent: String(manualObservation.densityPercent ?? ""),
					length: String(manualObservation.length ?? ""),
					sex: manualObservation.sex ?? "",
					comment: manualObservation.comment ?? ""
			  };

	const seenLabel = "Antall";
	const densityAmountLabel = "Antall/m²";
	const densityPercentLabel = "Tetthet (%)";
	const lengthLabel = "Lengde";
	const sexLabel = "Kjønn";
	const commentLabel = "Kommentar";

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={schema}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);

				try {
					const parsedData: ManualObservationFormData = {
						sex: values.sex === "" ? null : values.sex,
						length: values.length === "" ? null : Number(values.length),
						seen: values.seen === "" ? null : Number(values.seen),
						densityAmount: values.densityAmount === "" ? null : Number(values.densityAmount),
						densityPercent: values.densityPercent === "" ? null : Number(values.densityPercent),
						comment: values.comment === "" ? null : values.comment
					};

					await onSubmit(parsedData);
				} catch (err) {
					console.error(err);
				} finally {
					setSubmitting(false);
				}
			}}
		>
			{({ isSubmitting }) => (
				<Form>
					{/* Seen */}
					{fields.registerSeen ? (
						<FormFloating className="mb-3">
							<Field
								as={FormControl}
								id={`${fieldBaseId}-seen`}
								type="number"
								name="seen"
								placeholder={seenLabel}
							/>
							<FormLabel htmlFor={`${fieldBaseId}-seen`}>{seenLabel}</FormLabel>
							<ErrorMessage component={"p"} className="text-danger" name="seen" />
						</FormFloating>
					) : null}

					{/* Density amount */}
					{fields.registerDensityAmount ? (
						<FormFloating className="mb-3">
							<Field
								as={FormControl}
								id={`${fieldBaseId}-densityAmount`}
								type="number"
								name="densityAmount"
								placeholder={densityAmountLabel}
							/>
							<FormLabel htmlFor={`${fieldBaseId}-densityAmount`}>{densityAmountLabel}</FormLabel>
							<ErrorMessage component={"p"} className="text-danger" name="densityAmount" />
						</FormFloating>
					) : null}

					{/* Density percent */}
					{fields.registerDensityPercent ? (
						<FormFloating className="mb-3">
							<Field
								as={FormControl}
								id={`${fieldBaseId}-densityPercent`}
								type="number"
								name="densityPercent"
								placeholder={densityPercentLabel}
							/>
							<FormLabel htmlFor={`${fieldBaseId}-densityPercent`}>{densityPercentLabel}</FormLabel>
							<ErrorMessage component={"p"} className="text-danger" name="densityPercent" />
						</FormFloating>
					) : null}

					{/* Length */}
					{fields.registerLength ? (
						<FormFloating className="mb-3">
							<Field
								as={FormControl}
								id={`${fieldBaseId}-length`}
								type="number"
								name="length"
								placeholder={lengthLabel}
							/>
							<FormLabel htmlFor={`${fieldBaseId}-length`}>{lengthLabel}</FormLabel>
							<ErrorMessage component={"p"} className="text-danger" name="length" />
							<p className="text-muted">
								Kun én lengde kan registreres per observasjon. For å registrere flere, opprett flere
								observasjoner
							</p>
						</FormFloating>
					) : null}

					{/* Sex */}
					{fields.registerSex ? (
						<FormFloating className="mb-3">
							<Field as={FormSelect} id={`${fieldBaseId}-sex`} name="sex" placeholder={sexLabel}>
								<option value="">-- Velg --</option>
								{[...sexes].map(sex => (
									<option key={sex} value={sex.substring(1, 2)}>
										{sex}
									</option>
								))}
							</Field>
							<FormLabel htmlFor={`${fieldBaseId}-sex`}>{sexLabel}</FormLabel>
							<ErrorMessage component={"p"} className="text-danger" name="sex" />
						</FormFloating>
					) : null}

					{/* Comment */}
					<FormFloating className="mb-3">
						<Field
							as={FormControl}
							id={`${fieldBaseId}-comment`}
							type="text"
							name="comment"
							placeholder={commentLabel}
						/>
						<FormLabel htmlFor={`${fieldBaseId}-comment`}>{commentLabel}</FormLabel>
						<ErrorMessage component={"p"} className="text-danger" name="comment" />
					</FormFloating>
					<div className="d-flex justify-content-between">
						{/* Submit */}
						<Button variant="success" type="submit" disabled={isSubmitting}>
							{manualObservation !== undefined ? "Rediger" : "Opprett"}
						</Button>
						{/* Cancel */}
						<Button variant="danger" type="button" onClick={() => navigate(-1)}>
							Avbryt
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default ManualObservationForm;
export { ManualObservationForm };
