import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from "./en";
import nb from "./nb";

void i18next
	// Autodetect language settings from browser. Includes caching the result until next time
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	.init({
		// Debug in dev mode
		debug: process.env.NODE_ENV === "development",
		interpolation: {
			// React already escapes strings
			escapeValue: false
		},
		resources: {
			en,
			nb
		}
	});

export default i18next;
export { i18next };
