import * as yup from "yup";
import { BaseType, baseTypeSchema } from "./BaseType";

export interface Area extends BaseType {
	name: string;
	isUserCreated: boolean;
}

export const areaSchema = baseTypeSchema.shape({
	name: yup.string().required(),
	isUserCreated: yup.boolean().required()
});

areaSchema as yup.SchemaOf<Area>;
