import { BrowserRouter, Route, Routes } from "react-router-dom";
import CreateExcursion from "../pages/CreateExcursion";
import CreateStation from "../pages/CreateStation";
import Excursion from "../pages/Excursion";
import Excursions from "../pages/Excursions";
import EditExcursion from "../pages/EditExcursion";
import { Frontpage } from "../pages/Frontpage";
import ViewStation from "../pages/ViewStation";
import EditStation from "../pages/EditStation";

import NotFound from "./NotFound";
import { CreateLitterObseration, CreateLifeObservation } from "../pages/CreateManualObservation";
import EditManualObservation from "../pages/EditManualObservation";
import { RequireLogin } from "../application/RequireLogin";
import { Navbar } from "./Navbar";

const Router = (): JSX.Element => {
	return (
		<BrowserRouter>
			<Navbar />
			<Routes>
				<Route path="/" element={<Frontpage />} />
				<Route path="/excursion/:id" element={<Excursion />} />
				<Route
					path="/excursions"
					element={
						<RequireLogin userType="dfh-user">
							<Excursions />
						</RequireLogin>
					}
				/>
				<Route
					path="/createExcursion"
					element={
						<RequireLogin userType="dfh-user">
							<CreateExcursion />
						</RequireLogin>
					}
				/>
				<Route
					path="/editExcursion/:excursionId"
					element={
						<RequireLogin userType="dfh-user">
							<EditExcursion />
						</RequireLogin>
					}
				/>
				<Route
					path="/createStation/:excursionId"
					element={
						<RequireLogin>
							<CreateStation />
						</RequireLogin>
					}
				/>
				<Route
					path="/viewStation/:stationId"
					element={
						<RequireLogin>
							<ViewStation />
						</RequireLogin>
					}
				/>
				<Route
					path="/editStation/:stationId"
					element={
						<RequireLogin>
							<EditStation />
						</RequireLogin>
					}
				/>
				<Route
					path="/editManualObservation/:id"
					element={
						<RequireLogin>
							<EditManualObservation />
						</RequireLogin>
					}
				/>
				<Route
					path="/createManualObservation/:stationId/litter/:observationTypeId"
					element={
						<RequireLogin>
							<CreateLitterObseration />
						</RequireLogin>
					}
				/>
				<Route
					path="/createManualObservation/:stationId/life/:observationTypeId"
					element={
						<RequireLogin>
							<CreateLifeObservation />
						</RequireLogin>
					}
				/>
				{/* Catch-all if no routes are matched */}
				<Route path="*" element={<NotFound />} />
			</Routes>
		</BrowserRouter>
	);
};

export default Router;
export { Router };
