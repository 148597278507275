import { formatInTimeZone } from "date-fns-tz";

/**
 * Formats a date as UTC
 *
 * @param date The date to format
 *
 * @returns A string with format `yyyy-MM-dd` representing the input date in UTC timezone
 */
const formatToUTC = (date: Date) => {
	return formatInTimeZone(date, "UTC", "yyyy-MM-dd");
};

export { formatToUTC };
