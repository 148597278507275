import { Spinner } from "react-bootstrap";

const Loading = (): JSX.Element => (
	<Spinner animation="border" role="status">
		<span className="visually-hidden">Laster data</span>
	</Spinner>
);

export default Loading;
export { Loading };
