import { useState, useEffect } from "react";
import { ManualObservation } from "../models/ManualObservation";
import * as Backend from "../models/Backend";

/** Gets a manual Observation from the backend */
const useManualObservation = (id: ManualObservation["id"]): ManualObservation | null => {
	const [manualObservation, setManualObservation] = useState<ManualObservation | null>(null);
	useEffect(() => {
		void (async () => {
			const manualObservation = await Backend.getManualObservation(id);
			setManualObservation(manualObservation);
		})();
	}, [id]);

	return manualObservation;
};

export default useManualObservation;
export { useManualObservation };
