import { Excursion } from "./Excursion";
import * as yup from "yup";

export type ParticipantUser = {
	type: "participant";
	participantId: string;
	name: string;
	excursionId: Excursion["id"];
};

export type DFHUser = {
	type: "dfh-user";
	userId: string;
	name: string;
};

export type User = ParticipantUser | DFHUser;

export type RawUser = {
	excursionId: Excursion["id"] | null;
	name: string | null;
	ownername: string | null;
	participantId: string | null;
	userId: string | null;
};

export const rawUserSchema = yup.object({
	excursionId: yup.string().nullable(),
	name: yup.string().nullable(),
	ownername: yup.string().nullable(),
	participantId: yup.string().nullable(),
	userId: yup.string().nullable()
}) as yup.SchemaOf<RawUser>;
