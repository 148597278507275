import * as yup from "yup";
import { Area } from "./Area";
import { BaseType, baseTypeSchema } from "./BaseType";

export interface Excursion extends BaseType {
	owner: string;
	ownername: string | null;
	name: string;
	school: string;
	number: number;
	areaId: Area["id"];
	dateTime: Date;
	pin: number | null;
	isVirtual: boolean;
}

export const excursionSchema = baseTypeSchema.shape({
	owner: yup.string().required(),
	ownername: yup
		.string()
		.nullable()
		.transform((_cur, orig) => (orig === "" ? null : (orig as string))),
	name: yup.string().required(),
	school: yup.string().required(),
	number: yup.number().required(),
	areaId: yup.string().required(),
	dateTime: yup.date().required(),
	pin: yup.number().nullable(),
	isVirtual: yup.boolean().required()
});

excursionSchema as yup.SchemaOf<Excursion>;
